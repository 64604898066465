import baseTheme from '@bottlebooks/gatsby-theme-event/src/theme';
import merge from 'lodash/merge';
const theme = {
  ...baseTheme,
  fontUrls: [
    '/fonts/SohoStd_Regular.cc01175b16cf.otf',
    '/fonts/SohoStd_Medium.bd8b58ef5918.otf',
    '/fonts/SohoStd_Bold.ebfe6e996039.otf',
    '//use.typekit.net/slj6gjy.css',
  ],
  fonts: merge({}, baseTheme.fonts, {
    heading: 'Soho',
    body: 'hero-new',
  }),
  // fontSizes: {
  //   ...baseTheme.fontSizes,
  //   largestTitle: 42, // 68 in base
  //   largeTitle: 32, // 42 in base
  //   title: 24, // 32 in base
  //   smallTitle: 20, // 24 in base
  //   smallestTitle: 18, // 20 in base
  // },
  fontWeights: {
    ...baseTheme.fontWeights,
    heading: '500',
    body: '400',
  },
  // title: merge({}, baseTheme.title, {
  //   _base: {
  //     WebkitFontSmoothing: 'antialiased',
  //     textTransform: 'uppercase',
  //   }, // Add antialiasing to titles.
  // }),
  text: merge({}, baseTheme.text, {
    semiTitle: {
      ...baseTheme.text.semiTitle,
      fontSize: 'body',
      fontFamily: 'Soho',
    },
  }),

  // styles: {
  //   ...baseTheme.styles,
  //   root: {
  //     ...baseTheme.styles.root,
  //     WebkitFontSmoothing: 'subpixel-antialiased', // Garamond and DIN look bad with antialised in small sizes.
  //   },
  // },
};

// console.log(theme);
export default theme;
